import React from "react";
import logo from "../assets/images/logo.svg";

export default function Footer(props) {
  return (
    <footer className="main-footer">
        <div className="container">
          <div className="footer-card">
            <div className="footer-left">
              <a href="#"><img
              src={logo}
              className="img-fluid brand_logo"
              alt="logo"
            /></a>
            <p>An orci nullam tempor sapien, eget gravida integer donec ipsum porta justo at odio integer congue magna undo auctor gravida velna magna orci lacus odio ac risus auctor faucibus orci ligula massa luctus et ultrices posuere cubilia</p>
            </div>
            <div className="footer-right">
              <ul className="footer-links">
                <li><a href="#">Privacy Policy</a></li>
                <li>|</li>
                <li><a href="#">Terms & Conditions</a></li>
              </ul>
              <p>Follow us on</p>
              <ul className="social-links">
                <li><a href="#"><i className="fab fa-telegram-plane"></i></a></li>
                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fa-brands fa-x-twitter"></i></a></li>
                <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                <li><a href="#"><i class="fa-brands fa-whatsapp"></i></a></li>
                <li><a href="#"><i class="fa-brands fa-youtube"></i></a></li>
              </ul>
            </div>
          </div>
          <div className="footer-bottom">
            <p>Copyright 2024, BTX Verse, All rights reserved</p>
          </div>
        </div>
       </footer>
  );
}
