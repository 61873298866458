import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import $ from 'jquery';
export default function Navbar(props) {
  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      120 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      500 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });
  }
  
  return (
    <>
      <nav className="navbar navbar-dark navbar-expand main_navbar navbar-sticky">
        <div className="container">
          <Link className="navbar-brand" to="#">
            <img
              src={logo}
              className="img-fluid brand_logo"
              alt="logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right">
                {/* After Login Menus */}
                {/* <div className="dropdown me-3">
                  <a
                    className="primary_btn dropdown-toggle address_btn"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    0x75cf28f29266a413ec878ce5c4a1c9682c819586
                  </a>
                  <ul
                    className="dropdown-menu walletdrop "
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        <label>BUSD Balance:</label>
                        <span className="ms-2">250.08 </span>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Dashboard
                      </a>
                    </li>
                  </ul>
                </div>
                <button className="primary_btn">Logout</button> */}
                <button
                  className="primary_btn"                 
                >
                  Get the App
                </button>
                {/* <button
                  className="primary_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#connect_wallet_modal"
                >
                  Connect Wallet
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      {/* <div
        className="modal fade primary_modal"
        id="connect_wallet_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button">
                      <img
                        src={require("../assets/images/metamask.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>MetaMask</span>
                    </button>
                  </a>
                  <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button">
                      <img
                        src={require("../assets/images/trustwallet.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>Trust Wallet</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
