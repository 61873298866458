import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <span className="arrow-img"></span>
    </div>
  );
};

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
     <span className="arrow-img"></span>
    </div>
  );
};
export default function Home(props) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };
  
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="page_header">
        <div className="container">
        <div className="row">
        <div className="col-lg-6 m-auto">
          <h1>The Largest <span> Crypto Exchange </span> in the world</h1>
          <p>Lorem Ipsum is simply dummy text of the printing and setting indus orem Ipsum has been the industrys standard dummxt ever since the when an own printer  took a galley of type and scrambled it to make. a type specimen book.</p>
          <div class="input_box d-flex  gap-3"> 
            <input type="text" class="form-control"  aria-describedby="email" placeholder="Enter your email address"/> 
            <button className="primary_btn text-nowrap" >Get Started</button>
          </div>
          
        </div>
        <div className="col-lg-6 m-auto">
           <img src={require("../assets/images/banner_image.webp")} alt="img" className="img-fluid" />
        </div>
        </div>
        </div>
      </div>
      <div className="main_wrapper">
        <section className="home-promotion-slider">
          <div className="container">
            <div className="center-slider">
          <Slider {...settings}>
            <div className="sliderItem">
              <img src={require("../assets/images/slider-banner-1.png")} alt="img" className="img-fluid" />
            </div>
            <div className="sliderItem">
              <img src={require("../assets/images/slider-banner-2.png")} alt="img" className="img-fluid" />
            </div>
          </Slider>
          </div>
          </div>
        </section>
        <section className="features" >
           <div className="container">
            <div className="text-center" data-aos="fade-down" data-aos-offset="200">
             <img src={require("../assets/images/ban_blw1.png")} alt="img" className="img-fluid mob_img" />
             </div>
             <div className="outbox">
             <div className="row">
               <div className="col-lg-4">
                  <div className="box" data-aos="fade-up" data-aos-offset="200">
                    <img src={require("../assets/images/transaction.png")} alt="img" className="img-fluid" />
                    <h5>Easy Transactions</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and setting indus orem Ipsum has been the industrys standard dummxt ever since the when an own printer.</p>
                  </div>   
               </div>
               <div className="col-lg-4">
                  <div className="box" data-aos="fade-up" data-aos-offset="200">
                    <img src={require("../assets/images/safe.png")} alt="img" className="img-fluid" />
                    <h5>Safe & Protected</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and setting indus orem Ipsum has been the industrys standard dummxt ever since the when an own printer.</p>
                  </div>   
               </div>
               <div className="col-lg-4">
                  <div className="box right-borderless" data-aos="fade-up" data-aos-offset="200">
                    <img src={require("../assets/images/support.png")} alt="img" className="img-fluid" />
                    <h5>24/7 Support</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and setting indus orem Ipsum has been the industrys standard dummxt ever since the when an own printer.</p>
                  </div>   
               </div>
             </div>
             </div>
           </div>
        </section>
        <section className="detail_box" >
           <div className="container">
           <div className="outbox" data-aos="fade-up" data-aos-offset="200">
              <div className="row" >
                <div className="col-lg-3" >
                   <div className="box">
                     <p>Happy Customer</p>
                     <h4>50M+</h4>
                   </div>
                </div>
                <div className="col-lg-3" >
                   <div className="box">
                     <p>Positive Reviews</p>
                     <h4>50K</h4>
                   </div>
                </div>
                <div className="col-lg-3" >
                   <div className="box">
                     <p>Faster Process</p>
                     <h4>4X</h4>
                   </div>
                </div>
                <div className="col-lg-3" >
                   <div className="box right-borderless">
                     <p>Less expense p/m</p>
                     <h4>20%</h4>
                   </div>
                </div>
              </div>
              </div>
            </div>
        </section>
        <section className="easily_connect" >
           <div className="container">
             <div className="row mobile-reverse" data-aos="fade-up" data-aos-offset="200">
               <div className="col-lg-6">
                 <img src={require("../assets/images/easy_connect.png")} alt="img" className="img-fluid mob_img" />
               </div>
               <div className="col-lg-6">
                <div className="content-right-home">
                  <img src={require("../assets/images/message.png")} alt="img" className="img-fluid" />
                  <h2 className="h2tag" > <span>Easily connect </span> with your customer</h2>
                  <p>Lorem Ipsum is simply dummy text of the printing and setting indus orem Ipsum has been the industrys standard dummxt ever since the when an own printer  took a galley of type and scrambled it to make. a type specimen book.</p>
                 </div>
               </div>
             </div>
            </div>
        </section>
        <section className="easily_connect" >
           <div className="container">
             <div className="row" data-aos="fade-up" data-aos-offset="200">
               <div className="col-lg-6">
                <div className="content-right-home">
                  <img src={require("../assets/images/notification.png")} alt="img" className="img-fluid" />
                  <h2 className="h2tag" >Report history with <span>notifications</span></h2>
                  <p>Lorem Ipsum is simply dummy text of the printing and setting indus orem Ipsum has been the industrys standard dummxt ever since the when an own printer  took a galley of type and scrambled it to make. a type specimen book.</p>
                 </div>
               </div>
               <div className="col-lg-6">
                 <img src={require("../assets/images/report-notifications.png")} alt="img" className="img-fluid mob_img" />
               </div>
             </div>
            </div>
        </section>
        <section className="home-download-app">
          <div className="container">
            <div className="download-bg-blue" data-aos="fade-up" data-aos-offset="200">
              <div className="row">
                <div className="col-lg-7">
                  <h3>Download app & Register</h3>
                  <p>Lorem Ipsum is simply dummy text of the printing and setting indus orem Ipsum has been the industrys standard dummxt ever since the when an own printer  took a galley of type and scrambled it to make. a type specimen book.</p>
                  <div className="download-app-button-group">
                    <button className="btn white-button"><img src={require("../assets/images/play-store-button-icon.png")} alt="img" className="img-fluid" /> Play Store</button>
                    <button className="btn white-button"><img src={require("../assets/images/apple-store-button-icon.png")} alt="img" className="img-fluid" /> App Store</button>
                  </div>
                </div>
              </div>
                <img src={require("../assets/images/app-phone-banner-img.png")} alt="img" className="img-fluid appbanner-img" />
            </div>
          </div>
        </section>
        <section className="home-verification">
          <div className="container">
            <div className="row" data-aos="fade-up" data-aos-offset="200">
              <div className="col-lg-6">
                <div className="verificat-home-card">
                  <div className="verify-title">
                    <h3>Verify your Account</h3>
                    <img src={require("../assets/images/home-verify-icon.png")} alt="img" className="img-fluid" />
                  </div>
                  <p>Lorem Ipsum is simply dummy text of the printing and setting indus orem Ipsum has been the industrys standard dummxt ever.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="verificat-home-card">
                  <div className="verify-title">
                    <h3>Start using smart app</h3>
                    <img src={require("../assets/images/home-smartapp-icon.png")} alt="img" className="img-fluid" />
                  </div>
                  <p>Lorem Ipsum is simply dummy text of the printing and setting indus orem Ipsum has been the industrys standard dummxt ever.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="home-subscription">
            <div className="container">
              <div className="home-sub-card" data-aos="fade-up" data-aos-offset="200">
                <h2>Keep in touch with us for crypto exchange. Subscribe for Newsletter</h2>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" placeholder="Enter your email address" />
                  <span class="input-group-text" id="basic-addon2">
                    <img src={require("../assets/images/subscription-arrow.png")} alt="img" className="img-fluid" />
                  </span>
                </div>
              </div>
            </div>
        </section>
       <Footer />
      </div>
    </div>
  );
}
